import React, { useState, useEffect } from 'react'
import { PayPalButton } from 'react-paypal-button-v2'
import BuyBook from '../components/buybook'
import Layout from "../components/layout"
import SEO from "../components/seo"
import axios from 'axios';
import FormMessage from '../components/formmessage'


const onSuccess = ({cart, total, details, data, setCart, setNotifyMessage}) => {
    const response =  axios.post(
        '../send/purchase',
        //'http://localhost:4000/send/purchase',
        { cart: [...cart], total: total, ...data, details: {...details}},
        { headers: { 'Content-Type': 'application/json' } }
    ).then(res => {
        console.log(res)
        if(res.data.status === true){
            setCart([])
            setNotifyMessage('Thank you for your purchase from Alibi Press. We will be in touch with you soon.')
            //e.target.reset();
           

        }else {
            //setNotifyMessage("There");
        }
       
    }) 

  

}



const CartItem = ({ data, index, removeItem }) => (
    <div className="Cart-item">
        <p className="Cart-itemQuantity">{data.quantity}</p>
        <p className="Cart-itemTitle">{data.title}</p>
        <p className="Cart-itemPrice">{(data.price * data.quantity).toFixed(2)}</p>
        <button
            className="Cart-removeItem"
            onClick={() => removeItem(index)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="red" viewBox="0 0 30 30">
                <path id="Icon_material-cancel" data-name="Icon material-cancel" d="M18,3A15,15,0,1,0,33,18,14.986,14.986,0,0,0,18,3Zm7.5,20.385L23.385,25.5,18,20.115,12.615,25.5,10.5,23.385,15.885,18,10.5,12.615,12.615,10.5,18,15.885,23.385,10.5,25.5,12.615,20.115,18Z" transform="translate(-3 -3)" />
            </svg>
        </button>

    </div>
)


const Cart = ({ cart, total, removeItem, setCart, setNotifyMessage }) => {
   
    return (
        <div className="Cart">
            <h1>Cart</h1>
            <div className="Cart-tableHeading">
                <p className="Cart-itemQuantity">Quantity</p>
                <p className="Cart-itemTitle">Title</p>
                <p className="Cart-itemPrice">Price</p>
                <span className="Cart-removeItem"></span>
            </div>
            {cart.map((e, i) => <CartItem key={i} data={e} index={i} removeItem={removeItem} />)}
            <p className="Cart-total">
                <span className="extra-bold">Total:</span> ${total.toFixed(2)}
            </p>
            <div className="Cart-paypalBtn">
                <PayPalButton
                    amount={total}
                    currency="NZD"
                    onSuccess={(details, data) => onSuccess({
                        data: data, 
                        cart, 
                        total: total, 
                        setCart: setCart,
                        details: details,
                        setNotifyMessage
                    })}

                   
                    options={{
                        currency: "NZD",
                        locale: "en_NZ",
                        clientId: "AUjWTFoxOi0YOcr6rnIRilM7daPstr6cL9uf_QIGz1ZpMdNB3-eUL-tcRRg104cE0gUXl4ezt9I7VnZ4"
                    }}
                />

            </div>

        </div>
    )
}


const BuyPage = ({ data }) => {
    const [cart, setCart] = useState([])
    const [total, setTotal] = useState(0)
    const [notifyMessage, setNotifyMessage] = useState('');


    const addToCart = item => {

        if (isNaN(item.quantity)) return

        const ei = cart.findIndex(arrayItem => arrayItem.title === item.title)

        if (ei !== -1) {
            const tempArray = [...cart]

            tempArray.splice(ei, 1, { ...item, quantity: parseInt(item.quantity) + parseInt(cart[ei].quantity) })

            setCart(tempArray)
        } else {
            setCart(cart => [...cart, item])
        }


    }

    const removeItem = i => {

        const temp = [...cart]
        temp.splice(i, 1)
        setCart(temp)
    }

    useEffect(() => {

        if (cart.length === 0) return

        const ttl = cart.reduce((t, e) => {
            return t = t + (e.price * e.quantity)
        }, 0)

        setTotal(ttl)

    }, [cart])


    return (

        <Layout pageClass=''>
            <SEO title="Buy" />
            <main className='main'>
                <div className='typography inner'>
                    <h1>Buy</h1>
                    <div className="available-books">
                        {data.allMarkdownRemark.edges.map((e, i) => <BuyBook key={i} data={e} addToCart={addToCart} />)}
                    </div>


                    {cart.length > 0 ? 
                            <Cart 
                                cart={cart} 
                                total={total} 
                                removeItem={removeItem} 
                                setCart={setCart}
                                setNotifyMessage={setNotifyMessage} /> 
                        : 
                            <p className="cart-no-items">All prices are in New Zealand dollars and include postage and packaging within New Zealand. It is recommended that for orders of multiple books, or postage outside of New Zealand, you CONTACT US  and we will try and arrange specific delivery costs for your order. The current increased NZ Post delivery charges are not reflected in  prices for older books, and they are effectively discounted.</p>
                    }


                </div>

            </main>
            {notifyMessage ? <FormMessage message={notifyMessage} setNotifyMessage={setNotifyMessage} /> : ''}
        </Layout>

    )
}

export const pageQuery = graphql`
    {
        allMarkdownRemark(sort: {order: ASC, fields: [frontmatter___sortOrder]}, limit: 20, filter: {frontmatter: {isBeingPublished: {eq: true}}}) {
        edges {
            node {
            frontmatter {
                title
                subtitle
                anchor
                author
                price
                image {
                    altText
                    bookImage {
                        childImageSharp {
                        fluid(maxWidth: 75, maxHeight: 120, quality: 100, cropFocus: CENTER) {
                            ...GatsbyImageSharpFluid
                        }
                        }
                    }
                    }
                }
                html
            }
        }
        }
    }
  
`
export default BuyPage