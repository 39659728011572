import React, { useState, useRef } from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import TextInput from '../components/forms/TextInput'


const BuyBook = ({data, addToCart}) => {

    const { frontmatter } = data.node
    const inputRef = useRef()
    
    return (

        <div className="BuyBook">
            <div className="BuyBook-bookInfo">
                <div className="BuyBook-image">
                        <Img 
                            fluid={frontmatter.image.bookImage.childImageSharp.fluid}
                            alt={frontmatter.image.altText}
                            style={{
                                    width:'100%',
                                    display:'block'
                                }}              
                            />
                    </div>
                <h4 className="BuyBook-title">{frontmatter.title}</h4>
            </div>
           
            <div className="BuyBook-buyInfo">
                <p className="BuyBook-price">${frontmatter.price.toFixed(2)}</p>
                <TextInput ref={inputRef} defaultValue={1}/>
                <button 
                    className="BuyBook-addToCart" 
                    onClick={() => addToCart({
                                        quantity: inputRef.current.value,
                                        title: frontmatter.title,
                                        price: frontmatter.price
                                    })}>
                        add to cart
                    </button>
            </div>
            
        </div>
       
    )

}

export default BuyBook