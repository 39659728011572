import React from 'react'

const FormMessage = ({message, setNotifyMessage}) => {
    return (
        <>
        <div className="notify-overlay" onClick={() => setNotifyMessage(null)} onKeyDown={() => setNotifyMessage(null)} role="button" tabIndex="0">
            <div className="notification" onClick={() => setNotifyMessage(null)} onKeyDown={() => setNotifyMessage(null)}  role="button" tabIndex="-1">
                <p className="success-message">
                    {message}
                </p>
            </div>
        </div>
        
       
        </>
    );
}

export default FormMessage